<template>
  <label class="text-light">Accion</label>
  <div class="row">
    <div class="col-md-3">
      <select class="form-select" v-model="accion_id" @change="filtrarAccion">
        <option value="">Todos</option>
        <option value="Login">Login</option>
        <option value="Logout">Logout</option>
        <option value="Registro">Registro</option>
        <option value="Actualizar">Actualizar</option>
        <option value="Eliminar">Eliminar</option>
        <option value="Mail">Mail</option>
        <option value="Reset">Reset</option>
      </select>
    </div>
  </div>

  <div class="row" v-if="lstBitacora">
    <div class="card mt-2 table-responsive-sm">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Rol</th>
            <th>Accion</th>
            <th>Descripcion</th>
            <th>Fecha</th>
            <th>Hora</th>
          </tr>
        </thead>
        <tbody v-for="b of lstBitacora.data" v-bind:key="b.id">
          <tr style="cursor: pointer">
            <td>{{ b.email }}</td>
            <td>{{ getRol(b.role_id) }}</td>
            <td>{{ b.accion }}</td>
            <td>{{ b.descripcion }}</td>
            <td>{{ dateFormat(b.created_at) }}</td>
            <td>{{ timeFormat(b.created_at) }}</td>
          </tr>
        </tbody>
      </table>
      <Paginador
        :data="lstBitacora.links"
        endpoint="/api/v1/getBitacora"
        set="setBitacora"
        :parametros="'&accion=' + accion_id"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue-demi";
import Paginador from "../Paginador.vue";
import { useStore } from "vuex";
import useUtil from "../../use/useUtil";
import useBitacora from "../../composable/useBitacora";
export default {
  components: {
    Paginador,
  },
  setup() {
    const accion_id = ref("");
    const { dateFormat, timeFormat } = useUtil();
    const store = useStore();
    const { getBitacora, lstBitacora } = useBitacora();

    const filtrarAccion = async () => {
      store.commit("showLoader")
      await getBitacora({
        accion: accion_id.value,
        perPage: localStorage.getItem("perPage"),
      });
      store.commit("hideLoader") 
    };

    onMounted(async () => {
      store.commit("showLoader");
      await getBitacora({ accion: "", perPage: 5 });
      store.commit("hideLoader");
    });

    const getRol = (key) => {
      let result = "";
      switch (key) {
        case 1:
          result = "Administrador";
          break;
        case 2:
          result = "Supervisor";
          break;
        case 3:
          result = "Operador";
          break;
      }

      return result;
    };

    return {
      accion_id,
      filtrarAccion,
      dateFormat,
      timeFormat,
      getRol,
      lstBitacora,
    };
  },
};
</script>

<style>
</style>