<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Bitacora</h3>
    <div class="row">
      <BitacoraTables />
    </div>
  </div>
</template>

<script>
import BitacoraTables from "../components/Table/BitacoraTables.vue";

export default {
  components: {
    BitacoraTables,
  },
  setup() {
    return {};
  },
};
</script>

<style>
</style>