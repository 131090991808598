import { computed } from "vue";
import { useStore } from "vuex";


const useBitacora = () =>{
    const store = useStore();

    const getBitacora = async (bitacora) => {        
        const resp = await store.dispatch("getBitacora", bitacora);
        return resp;
      };

    return {
        getBitacora,
        lstBitacora:computed(() => store.getters['getBitacora'])
    }

}

export default useBitacora;